<template>
  <div class="px-5" id="page-events">
    <h4 class="mb-4 mt-4 ml-2">Evenemang för {{ company.name }}</h4>

    <EventList
      :company="company"
      :events="events"
      @selectEventClicked="selectEventClicked"
    />

  </div>
</template>

<script>
import messageParent from '@/core/services/messageParent';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { mapGetters } from 'vuex';
import EventList from '@/view/pages/members/public/EventList.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'event-page',
  components: {
    EventList,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentMember'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      company: { company_id: 'aaaa', name: '' },
      events: [],
    };
  },
  watch: {
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    async loadData() {
      this.company.company_id = this.$route.params.id;
      await this.loadCompany();
      await this.loadEvents();
    },
    async loadEvents() {
      // if (!this.currentMember) {

        const loader = this.$loading.show();
        this.events = [];
        const res = await axios.get(`/events?company_id=${this.company.company_id}`)

        if (res.status === 200) {

          var events = res.data;
          var now = dayjs();
          for (var k in events) {
            var comp = events[k];

            for (var i = 0; i < comp.length; ++i) {

              var eventdt = dayjs(comp[i].to_datetime);
              if (eventdt < now) {
                continue;
              }
              //check event date
              this.events.push({ event: comp[i], client_event: null});
            }
          }

          // Sort events by start time, earliest first
          this.events.sort((a, b) => {
            return dayjs(a.event.from_datetime) - dayjs(b.event.from_datetime);
          });

          setTimeout(function(){
            messageParent(JSON.stringify({ height: document.body.scrollHeight }));
          }, 300);
        } else {
          console.error(res);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista evenemang');
        }
        loader & loader.hide();
      // } else {
      //   axios
      //     .get(`/events/listformember?company_id=${this.company.company_id}&member_id=${this.currentMember.member_id}`) // ----- from_datetime is ''
      //     .then(res => {
      //       let now = dayjs();
      //       const events = res.data.filter(function(item) {
      //         let eventdt = dayjs(item.event.from_datetime);
      //         return now < dayjs(item.event.to_datetime);
      //       });
      //       if (events === undefined)
      //         this.events = [];
      //       else
      //         this.events = events;
      //     })
      //     .catch(err => {
      //       console.error(err);
      //       this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta evenemang');
      //     });
      // }
    },
    loadCompany() {
      axios
        .get(`/company/public/${this.company.company_id}`)
        .then(res => {
          this.company = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta förening');
        });
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    selectEventClicked() {},
    
  }
};
</script>
